<template>
  <div class="friendAdd">
    <watch-head :title="$t('添加好友')" :img="require('@/assets/bracelet_img/7.png')"></watch-head>
    <div class="content">
      <van-search v-model="keyword"
                  left-icon=""
                  :class="{'vn-italic': $i18n.locale == 'vi'}"
                  :placeholder="$t('请输入搜索关键词')"
                  class="search">
        <template #right-icon>
          <img class="right-icon"
               src="@/assets/bracelet_img/6.png"
               @click="search"
               mode="">
        </template>
      </van-search>
      <div class="recommend" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('推荐玩家')}}</div>
      <van-list v-model="loading"
                :finished="finished"
                :class="{'vn-italic': $i18n.locale == 'vi'}"
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中...')"
                :immediate-check="false"
                :offset="20"
                @load="onLoad"
                class="list">
        <div class="item"
             v-for="(item, index) in list"
             :key="index">
          <div class="right">
            <div class="img">
              <van-image class="image"
                         lazy-load
                         round
                         :src="item.avatar" />
              <div class="text">{{ item.level_grade }}</div>
            </div>
            <div class="info">
              <div class="name" :class="{'vn-italic': $i18n.locale == 'vi'}">{{ item.nickname ? item.nickname : $t('暂无昵称') }}</div>
              <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('排行')}} :  {{ item.income_ionz_ranking == 0 ? '100+' : item.income_ionz_ranking }}</div>
            </div>
          </div>
          <van-button v-show="item.is_Request == 0" @click="addFriend(item.user_id, index)" :loading="item.addLoading" :class="item.addLoading ? 'bgc_act' : 'bgc'" loading-size="34px" :icon="require('@/assets/bracelet_img/1.png')" round />
        </div>
      </van-list>
    </div>
    <div class="btm">
      <!-- <div class="refresh" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('刷新')}}</div> -->
      <div class="request_add">
        <div class="left">
          <div class="box"
               @click="goBack">
            <img src="@/assets/bracelet_img/5.png"
                 alt="">
          </div>
        </div>
        <div class="right">
          <van-badge class="request pointer"
                     :content="friendsnum > 0 ? friendsnum : '' "
                     max="99">
            <div @click="goTo" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('请求')}}</div>
          </van-badge>
          <div class="add pointer" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('添加好友')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watchHead from '@/components/watchHead'
import { getRecommend, addFriendsRequest } from '@/api/api'
import { addFriend, getFriendAddCount, timIsLogin } from '@/utils/tim.js'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      addLoading: false,
      keyword: '',
      loading: false,
      finished: false,
      page: 1,
      list: [],
      friendsnum: 0,
    }
  },
  components: {
    watchHead,
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { this.waitForGlobal(); },
  methods: {
    // im 添加好友
    addFriend(id, index){
      if (!this.list[index].addLoading) {
        this.list[index].addLoading = true
        addFriend(id).then(res => {
          const { code } = res.data;
          // code: 30539 说明用户设置了【需要经过自己确认对方才能添加自己为好友】 ;  code: 0 说明用户设置了【允许任何人添加自己为好友】
          if (code === 30539 || code === 0) {
            this.list[index].addLoading = false
            this.$toast(this.$t('成功'));
            this.list[index].is_Request = 1
          }
        }).catch(err => {
          console.warn('添加好友的请求发送失败------', err); // 添加好友失败的相关信息
          this.list[index].addLoading = false
          this.$toast(err);
        });
      }
    },
    addTo (id, index) {
      if (!this.addLoading) {
        this.addLoading = true
        addFriendsRequest({ id }).then(res => {
          if (res.code == 0) {
            this.list[index].is_Request = 1
          }
          this.addLoading = false
          this.$toast(res.msg);
        })
      }
    },
    search () {
      if (!this.loading) {
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
      }
    },
    // 推荐好友列表
    onLoad () {
      getRecommend({
        keyword: this.keyword,
        page: this.page
      }).then(res => {
        if (res.code == 0) {
          let arr = res.data.list
          arr = arr.map((item) => {
            return {
              ...item,
              is_Request: 0,
              addLoading: false,
            }
          })
          this.list = this.list.concat(arr)
          this.loading = false;
          if (res.data.page.last_page <= this.page) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    goTo () {
      this.$router.push({
        path: "/friendRequest"
      })
    },
    /* 返回上一页 */
    goBack () {
      this.$router.go(-1);
    },
    /* 获取统计 */
    getFriendAddCount(){
      getFriendAddCount().then(res => {
        this.friendsnum = res
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && timIsLogin) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
        this.getFriendAddCount();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.friendAdd {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .content {
    padding: 18px 15px 0;
    .search {
      padding: 0;
      border-radius: 4px;
      margin-bottom: 26px;
      .van-field {
        padding: 4px 12px 4px 0;
      }
      /deep/.van-field__right-icon {
        display: flex;
        align-items: center;
      }
      .right-icon {
        width: 26px;
        height: 26px;
      }
    }
    .recommend {
      font-family: '071-SSRuiFengTi';
      font-size: 30px;
      margin-bottom: 15px;
    }
    .list {
      height: 54vh;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .right {
          width: 85%;
          display: flex;
          align-items: center;
          .img {
            width: 60px;
            min-width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: solid 4px #2b2b2b;
            position: relative;
            margin-right: 22px;
            .image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 26px;
              height: 26px;
              line-height: 26px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -14px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
            }
          }
          .info {
            width: calc(100% - 82px);
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .Ranking {
              font-family: 'AeroMaticsDisplayRegular';
              font-size: 16px;
            }
          }
        }
        .left {
          width: 48px;
          min-width: 48px;
          height: 48px;
          background-color: #6065ce;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22px;
            height: 22px;
          }
        }
        /deep/.van-button{
          width: 33.75px;
          min-width: 33.75px;
          height: 33.75px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          color: #fff;
          padding: 0;
          .van-icon{
            img{
              width: 18.75px;
              height: 18.75px;
            }
          }
        }
        .bgc{
          background-color: #6065ce;
        }
        .bgc_act{
          background-color: transparent;
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
  }
  .btm {
    width: 100%;
    font-family: '071-SSRuiFengTi';
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 7px;
    .refresh {
      padding: 4px 75px;
      background-color: #8ff9f6;
      border-radius: 15px 4px 15px 4px;
      border: 2px solid #261e36;
      font-size: 30px;
      color: #2b2b2b;
      margin-bottom: 12px;
    }
    .request_add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 17%;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 0 0 34px 0;
        padding: 8px 8px 8px 0;
        .box {
          background-color: #2b2b2b;
          border-radius: 0 0 32px 0;
          border: solid 2px #7736fe;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 6px 8px 6px 8px;
          img {
            width: 26px;
            height: 18px;
          }
        }
      }
      .right {
        width: 82%;
        display: flex;
        align-items: center;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 36px 0 0 0;
        padding: 8px 56px 8px 8px;
        text-align: center;

        .request {
          background-color: #5c65cb;
          border-radius: 32px 2px 2px 2px;
          border: solid 2px #261e36;
          margin-right: 8px;
          width: 112px;
          font-size: 22px;
          color: #2b2b2b;
          padding: 2px 0;
        }
        .add {
          background-color: #8ff9f6;
          border-radius: 3px 3px 15px 3px;
          border: solid 2px #261e36;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
          padding: 2px 0;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .friendAdd {
    .btm {
      width: 430px;
    }
  }
}
</style>
